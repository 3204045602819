import request from '../request'

//银行卡列表
export const bank_card_index_request = p => {
    return request({
        method:'GET',
        url:'bank_card/index',
        params: p
    })
}

//添加银行卡
export const bank_card_add_request = d => {
    return request({
        method:'POST',
        url:'bank_card/add',
        data: {
            user_name: d.user_name,
            card: d.card,
            bank: d.bank,
            type: d.type
        }
    })
}

//删除银行卡
export const bank_card_del_request = id => {
    return request({
        method:'DELETE',
        url:'bank_card/del',
        data: {
            id: id
        }
    })
}

//修改银行卡
export const bank_card_edit_request = d => {
    return request({
        method:'PUT',
        url:'bank_card/edit',
        data: {
            id: d.id,
            user_name: d.user_name,
            card: d.card,
            bank: d.bank,
            type: d.type
        }
    })
}

//获取银行卡详情
export const bank_card_details_request = id => {
    return request({
        method:'GET',
        url:'bank_card/details',
        params: {
            id
        }
    })
}